import React from 'react';
import './index.scss';
import { ReactComponent as CarretIcon } from '../../../assets/carret.svg';

type ResetButtonProps = {
  onClick: () => void;
  title: string;
  isLoading?: boolean;
  isSticky?: boolean;
};

const ResetButton = ({ onClick, title, isLoading, isSticky }: ResetButtonProps) => (
  <div
    onClick={onClick}
    className={`reset-button ${isSticky ? 'sticky' : ''}`}
    data-testid='reset-button'
  >
    <CarretIcon className='rotate-270' />
    {title}
    {isLoading && <div className='blue-spinner' data-testid='reset-spinner' />}
  </div>
);

export default ResetButton;
