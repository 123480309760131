import React from 'react';
import { formatAmount } from '../../../../../../../utils/helper';
import { StandingOrderModel } from '../../../../../../../types/Overview/StandingOrderModel';
import './index.scss';

interface StandingOrderAlertProps {
  order: StandingOrderModel;
}

const StandingOrderAlert = ({ order }: StandingOrderAlertProps) => (
  <div className='standing-order-alert' data-testid='thanks-standing-order'>
    <div className='col-2'>
      <div className='name line-clamp-1'>{order.recipientIban}</div>
      <div className='category line-clamp-1'>{order.description}</div>
    </div>
    <div className='col-3'>
      <div className='amount'>-{formatAmount(order.amount.toString())} €</div>
      <div className='frequency'>{order.frequencyLocale}</div>
    </div>
  </div>
);

export default StandingOrderAlert;
