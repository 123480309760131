import React, { useEffect } from 'react';
import Container from '../../../Common/Container';
import { formatAmount, removeNegativeSign } from '../../../../utils/helper';
import './index.scss';

interface IntroProps {
  data: any;
  pageType: 'notification' | 'standingOrder';
  classNames?: string;
}

const Intro = ({ data, pageType, classNames }: IntroProps) => {
  useEffect(() => {
    data?.transactions?.sort(
      (a: any, b: any) =>
        new Date(b.bookingDate || Date.now()).valueOf() -
        new Date(a.bookingDate || Date.now()).valueOf(),
    );
  }, [data]);

  const amount =
    pageType === 'notification'
      ? data?.transactions?.length && data.transactions?.[0].amount
      : data.amount;

  return (
    <div className={`intro ${classNames ?? ''}`} data-testid='intro'>
      <Container classNames='equal-padding'>
        <div className='row-1'>
          <div className='col-2'>
            <div className='name'>
              {pageType === 'notification' ? data?.normalizedCounterName : data?.recipientName}
            </div>
            <div className='category'>
              {pageType === 'standingOrder' ? data?.description : data?.categoryLocale}
            </div>
          </div>
        </div>
        {amount && amount !== 0 ? (
          <div className='col-3'>
            <div className='amount'>
              {`${pageType === 'notification' && amount > 0 ? '+' : '-'}${removeNegativeSign(
                formatAmount(amount),
              )} €`}
            </div>
            <div className='frequency'>{data?.frequencyLocale}</div>
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default Intro;
