/* eslint-disable no-useless-escape */
import { parseUserInputDate } from './helper';

const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EMAIL_OR_NULL_REGEXP =
  /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const GERMAN_IBAN_REGEXP = /^DE\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$/i;

const GERMAN_IBAN_OR_ANY_FOREIGN_REGEXP =
  /^(?!DE)[A-Z]{2}[0-9A-Z]*$|^DE\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$/i;

const EMPTY_REGEXP = /^(?!\s*$).+/;

const MORE_THAN_2_CHAR_REGEXP = /[a-z0-9]{2,}/i;

const MORE_THAN_2_CHAR_OR_NULL_REGEXP = /^$|[a-z0-9]{2,}/i;

const PHONE_REGEXP = /^(((\+|00)?49)|0)[1-9]{1}\d{5,12}$/;

const PHONE_OR_NULL_REGEXP = /^$|^(((\+|00)?49)|0)[1-9]{1}\d{5,12}$/;

const POST_CODE_REGEXP = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/;

export interface Validator {
  validate(input: string): ValidationResult;
}

export interface ValidationResult {
  valid: boolean;
  message?: string;
}

export type ValidationErrorNotification = Record<string, Record<string, ValidationResult>>;

class Validators {
  static dateValidator(): Validator {
    return {
      validate(input: string): ValidationResult {
        const parsedDate = parseUserInputDate(input);
        const valid = parsedDate.isValid();
        return {
          valid,
          message: valid ? '' : 'Bitte geben Sie ein gültiges Datum ein',
        };
      },
    };
  }

  static email(): Validator {
    return Validators.regexp(EMAIL_REGEXP, 'Bitte geben Sie eine gültige E-Mail Adresse ein');
  }

  static emailOrNull(): Validator {
    return Validators.regexp(
      EMAIL_OR_NULL_REGEXP,
      'Bitte geben Sie eine gültige E-Mail Adresse ein',
    );
  }

  static germanIban(): Validator {
    return Validators.regexp(GERMAN_IBAN_REGEXP, 'Bitte geben Sie eine gültige IBAN ein');
  }

  static germanIbanOrAnyForeign(): Validator {
    return Validators.regexp(
      GERMAN_IBAN_OR_ANY_FOREIGN_REGEXP,
      'Bitte geben Sie eine gültige IBAN ein',
    );
  }

  static empty(): Validator {
    return Validators.regexp(EMPTY_REGEXP, 'Bitte geben Sie die fehlenden Informationen ein');
  }

  static moreThan2(): Validator {
    return Validators.regexp(
      MORE_THAN_2_CHAR_REGEXP,
      'Ihre Eingabe muss mindestens zwei Buchstaben enthalten.',
    );
  }

  static moreThan2orNull(): Validator {
    return Validators.regexp(
      MORE_THAN_2_CHAR_OR_NULL_REGEXP,
      'Ihre Eingabe muss mindestens zwei Buchstaben enthalten.',
    );
  }

  static phone(): Validator {
    return Validators.regexp(PHONE_REGEXP, 'Bitte geben Sie eine gültige Telefonnummer ein');
  }

  static phoneOrNull(): Validator {
    return Validators.regexp(
      PHONE_OR_NULL_REGEXP,
      'Bitte geben Sie eine gültige Telefonnummer ein',
    );
  }

  static zipCode(): Validator {
    return Validators.regexp(POST_CODE_REGEXP, 'Bitte geben Sie eine Postleitzahl ein');
  }

  static regexp(expression: RegExp, message: string): Validator {
    return {
      validate(input: string): ValidationResult {
        const m = expression.exec(input);
        const valid = m !== null;
        return {
          valid,
          message: valid ? '' : message,
        };
      },
    };
  }
}

export default Validators;
