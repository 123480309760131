export enum ItemState {
  NOTIFY = 'NOTIFY',
  IGNORE = 'IGNORE',
  INCOMPLETE = 'INCOMPLETE',
  TO_COMPLETE = 'TO_COMPLETE',
  APPROVED = 'APPROVED',
  SCHEDULED = 'SCHEDULED',
  MISSING_CRITICAL_INFORMATION = 'MISSING_CRITICAL_INFORMATION',
  DISABLED = 'DISABLED',
  OWN_IBAN = 'OWN_IBAN',
  SENT = 'SENT',
  EXECUTED = 'EXECUTED',
}

export enum ItemAction {
  MESSAGE = 'MESSAGE',
  MANUAL = 'MANUAL',
  REMIND = 'REMIND',
  IGNORE_FOREIGN_COUNTRY = 'IGNORE_FOREIGN_COUNTRY',
}

export enum ItemType {
  NOTIFICATION = 'NOTIFICATION',
  STANDING_ORDER = 'STANDING_ORDER',
  CLOSING_DATA = 'CLOSING_DATA',
}

export enum Frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  BIMONTHLY = 'BIMONTHLY',
  QUARTERLY = 'QUARTERLY',
  HALF_YEARLY = 'HALF_YEARLY',
  YEARLY = 'YEARLY',
  BIYEARLY = 'BIYEARLY',
  IRREGULAR = 'IRREGULAR',
  UNKNOWN = 'UNKNOWN',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NA = 'NA',
}

export interface CustomerDataModel {
  firstName: string;
  lastName: string;
  birthDate: string;
  address: CustomerAddress;
  mobileNumber: string;
  email: string;
  gender: Gender;
}

export interface CustomerAddress {
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  country: string;
  countryCode: string;
}

export interface RecipientAddress {
  street?: string;
  streetNum?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  countryCode?: string;
  postfach?: string;
  germanAddress?: boolean;
}

export interface Recipient {
  id?: number | string;
  name?: string;
  department?: string;
  address?: RecipientAddress;
  email?: string;
  fax?: string;
  phone?: string;
}

export interface RecipientSearchModel {
  id: string;
  name: string;
  postalCode?: string;
  city?: string;
  country?: string;
  street?: string;
  streetNum?: string;
  email?: string;
  postfach?: string;
  fax?: string;
}

export interface TransactionModel {
  subject: string;
  counterName: string;
  amount: number;
  bookingDate: string;
}

export interface Dates {
  asap: boolean;
  invalidSwitchingDate: boolean;
  switchingDate: string;
  earliestSwitchingDate: string;
  inputSwitchingDate?: string | null;
}

export interface ExecutionDates extends Dates {
  firstExecutionNewBankDate: string;
  originalEndExecutionDate: string;
}
