import React from 'react';
import { useOverview } from '../../../../../../../contexts/OverviewContext/OverviewContext';
import TransferBankCard from '../../../../../../Common/TransferBankCard';
import { formatDate } from '../../../../../../../utils/helper';
import './index.scss';

interface DefaultSuccessSectionProps {
  isAccountClosingOnly: boolean;
  stateChip: React.JSX.Element | null;
  tooltip: React.JSX.Element;
}

const DefaultSuccessSection = ({
  isAccountClosingOnly,
  stateChip,
  tooltip,
}: DefaultSuccessSectionProps) => {
  const { overviewData } = useOverview();
  const { globalDate, closingData } = overviewData!;

  return (
    <div className='default-success-section' data-testid='default-success-section'>
      {stateChip}
      <div className='default-section-header'>
        <h2>
          {isAccountClosingOnly
            ? `Ihre Kontoschließung zum ${formatDate(closingData.accountClosingDate)}`
            : `Ihr Kontoumzug ${globalDate.allSwitchingDatesMatch ? 'zum' : 'ab '} ${formatDate(
                globalDate.globalSwitchingDate,
              )}`}
        </h2>
        {tooltip}
      </div>
      <TransferBankCard oldBank={closingData.oldBank} newBank={closingData.newBank} />
    </div>
  );
};

export default DefaultSuccessSection;
