import React from 'react';
import './index.scss';

import Container from '../../../../Common/Container';
import { ReactComponent as CheckIcon } from '../../../../../assets/check2.svg';
import { formatDate, isApprovedState } from '../../../../../utils/helper';
import Tooltip from '../../../../Common/Tooltip';
import { useOverview } from '../../../../../contexts/OverviewContext/OverviewContext';
import { TooltipStateEnum } from '../../../../../contexts/TooltipStateContext/Tooltip.types';
import { NotificationModel } from '../../../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../../../types/Overview/StandingOrderModel';
import { useKwsState } from '../../../../../contexts/KwsStateContext/KwsStateContext';
import { ProcessStateEnum } from '../../../../../contexts/KwsStateContext/KwsState.types';
import StateInfoChips, { StateInfoChipEnum } from '../../../../Common/StateInfoChips';
import { UserStatusEnum } from '../../../../../types/SignaturePage/SignaturePageModel';
import DefaultSuccessSection from './components/DefaultSuccessSection';
import InfoBox from '../../../../Common/InfoBox';

const SuccessComponentTooltip: React.JSX.Element = (
  <Tooltip tooltipSectionName={TooltipStateEnum.SUCCESS_SECTION}>
    <p>
      Im Folgenden haben wir für Sie nochmals alle durchgeführten Umzugsschritte zusammengefasst.
      Einige davon benötigen ggf. noch Ihre Mithilfe.
    </p>
    <br />
    <p>
      Bei Bedarf können wir jederzeit bisher noch nicht ausgewählte Umzugsschritte für Sie
      durchführen. Scrollen Sie dazu einfach zum Abschnitt „Umzugsschritte hinzufügen“ auf dieser
      Seite.
    </p>
  </Tooltip>
);

interface SuccessSectionProps {
  userState: UserStatusEnum;
}

const SuccessSection = ({ userState }: SuccessSectionProps) => {
  const { overviewData } = useOverview();
  const { globalDate, notifications, standingOrders, closingData, customerData } = overviewData!;
  const { kwsState } = useKwsState();

  const anyApprovedState = (data: NotificationModel[] | StandingOrderModel[]): boolean =>
    data.some((item: NotificationModel | StandingOrderModel) => isApprovedState(item.state));

  const isAccountClosingOnly =
    isApprovedState(closingData.state) &&
    !(anyApprovedState(notifications) || anyApprovedState(standingOrders));

  const createStateChip = (): React.JSX.Element | null => {
    if (kwsState!.state === ProcessStateEnum.READY) {
      return (
        <StateInfoChips type={StateInfoChipEnum.SUCCESS} label='beauftragt'>
          Ihr Kontoumzug wurde erfolgreich beauftragt. Wir prüfen Ihre Eingaben und bereiten Ihre
          ausgewählten Umzugsschritte zum jeweiligen Datum vor.
        </StateInfoChips>
      );
    }
    if (kwsState!.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_BANK) {
      return (
        <StateInfoChips type={StateInfoChipEnum.ALERT} label='IBAN ausstehend'>
          Ihre neue {closingData.newBank.name} IBAN wird automatisch innerhalb von wenigen Tagen
          übermittelt, sodass Ihr Kontoumzug durchgeführt werden kann.
        </StateInfoChips>
      );
    }
    if (kwsState!.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_CUSTOMER) {
      return (
        <StateInfoChips type={StateInfoChipEnum.ALERT} label='IBAN ausstehend'>
          Leider wurde uns Ihre neue {closingData.newBank.name} IBAN nicht automatisch übermittelt.
          Bitte geben Sie Ihre IBAN ein, damit Ihr Kontoumzug durchgeführt werden kann.
        </StateInfoChips>
      );
    }

    return null;
  };

  return (
    <Container>
      {userState === UserStatusEnum.READY || userState === UserStatusEnum.UPDATED ? (
        <div className='success-section'>
          <div className='success'>
            <div className='success-title'>
              <CheckIcon />
              <h2 data-testid='success-msg' className='text'>
                {isAccountClosingOnly
                  ? 'Vielen Dank, Ihre Kontoschließung wurde beauftragt'
                  : `Vielen Dank, Ihr Kontoumzug wurde ${
                      userState === UserStatusEnum.UPDATED ? 'aktualisiert' : 'beauftragt'
                    }`}
              </h2>
            </div>
            {SuccessComponentTooltip}
          </div>
          <div data-testid='ready-updated-content'>
            <p className='title'>
              Wir haben Ihnen eine Bestätigung an <span>{customerData.email}</span> gesendet.
            </p>
            <div className='account-info'>
              <div className='row'>
                <span>Vorgangsnummer</span>
                <span className='data'>{kwsState!.processNumber}</span>
              </div>
              <div className='row'>
                <span>{isAccountClosingOnly ? 'Kontoschließung' : 'Kontoumzugsdatum'}</span>
                <span className='data' data-testid='date-info'>
                  {globalDate.allSwitchingDatesMatch ? '' : 'ab '}
                  {isAccountClosingOnly
                    ? formatDate(globalDate.accountClosingDate)
                    : formatDate(globalDate.globalSwitchingDate)}
                </span>
              </div>
              <div className='row'>
                <span>Status</span>
                {createStateChip()}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <DefaultSuccessSection
          isAccountClosingOnly={isAccountClosingOnly}
          stateChip={createStateChip()}
          tooltip={SuccessComponentTooltip}
        />
      )}

      {kwsState?.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_BANK && (
        <div className='success-info-box-wrapper' data-testid='success-waiting-for-iban'>
          <InfoBox type='info'>
            <p>
              Die {closingData.newBank.name} übermittelt Ihre neue IBAN automatisch innerhalb von
              wenigen Tagen, sodass Ihr Kontoumzug durchgeführt werden kann.
            </p>
          </InfoBox>
        </div>
      )}
    </Container>
  );
};

export default SuccessSection;
