import React from 'react';
import './index.scss';
import Container from '../../../../../Common/Container';
import { OverviewBankModel } from '../../../../../../types/Overview/ClosingDataModel';
import BankCard from '../../../../../Common/BankCard';

interface WelcomeSectionProps {
  oldBank: OverviewBankModel;
}

const WelcomeSection: React.FC<WelcomeSectionProps> = ({ oldBank }: WelcomeSectionProps) => (
  <Container classNames='mobile-shadow equal-padding detail-pages-bottom-margin'>
    <div className='welcome-section-account-closing' data-testid='welcome-section-account-closing'>
      <p className='text'>Kontoschließung</p>
      <BankCard name={oldBank.name} iban={oldBank.iban} logoName={oldBank.logoName} />
    </div>
  </Container>
);

export default WelcomeSection;
