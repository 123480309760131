import React, { useState } from 'react';
import './index.scss';

import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import Container from '../../../Common/Container';
import Notification from './components/Notification';
import NotificationCategory from './components/NotificationCategory';
import Sort from '../../../Common/Sort';
import { ReactComponent as CarretIcon } from '../../../../assets/carret.svg';
import {
  filterRelevantNotifications,
  isApprovedState,
  setLastScrollPosition,
} from '../../../../utils/helper';
import Tooltip from '../../../Common/Tooltip';
import { useOverview } from '../../../../contexts/OverviewContext/OverviewContext';
import { WorkflowEnum } from '../../../../contexts/KwsStateContext/KwsState.types';
import InnerBorderContainer from '../../../Common/InnerBorderContainer';
import { NotificationModel, NotificationType } from '../../../../types/Overview/NotificationModel';
import { Frequency, ItemState } from '../../../../types/Overview/Common';
import { INotificationCategoriesModel } from '../../../../contexts/OverviewContext/Overview.types';
import InfoBox from '../../../Common/InfoBox';
import AddButton from '../../../Common/AddButton';
import { TooltipStateEnum } from '../../../../contexts/TooltipStateContext/Tooltip.types';

const NotificationsSection = () => {
  const [expand, setExpand] = useState(sessionStorage.getItem('c24-partner') === 'true');
  const { kwsState, setNotificationToProcess } = useKwsState();
  const { overviewData, notificationCategories, sortNotifications } = useOverview();

  const toggleExpand = () => {
    sessionStorage.setItem('c24-partner', (!expand).toString());
    setExpand(!expand);
  };

  const createNewNotification = () => {
    setLastScrollPosition();
    setNotificationToProcess({
      pageType: 'ADD',
      accountClosingDate: overviewData?.closingData.accountClosingDate,
    });
  };

  const { notifications } = overviewData!;

  const hasAutomaticNotifications = notifications?.some(
    (notification: NotificationModel) => !notification.added,
  );

  const manualOrHasAutomaticNotifications =
    kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN || !hasAutomaticNotifications;

  const manualAndNoNotification =
    kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN && notifications?.length === 0;

  const accountCategories = ['ONLINE_SHOPPING', 'PAYMENTS', 'SHOPPING'];
  const otherCategories = ['INVESTMENT', 'OTHERS'];

  const relevantNotifications = filterRelevantNotifications(notifications);

  const regularNotifications = relevantNotifications?.filter(
    (notification: NotificationModel) =>
      !notification.private &&
      ((!accountCategories.includes(notification.category) &&
        !otherCategories.includes(notification.category)) ||
        (otherCategories.includes(notification.category) &&
          notification.frequency !== Frequency.IRREGULAR)),
  );
  const regularNotificationsActive = regularNotifications?.filter(
    (notification: NotificationModel) => !notification.inactive,
  );
  const regularNotificationsInactive = regularNotifications?.filter(
    (notification: NotificationModel) => notification.inactive,
  );

  const accountNotifications = relevantNotifications?.filter(
    (notification: NotificationModel) =>
      !notification.private &&
      (accountCategories.includes(notification.category) ||
        (otherCategories.includes(notification.category) &&
          notification.frequency === Frequency.IRREGULAR)),
  );

  const accountNotificationsActive = accountNotifications?.filter(
    (notification: NotificationModel) => !notification.inactive,
  );
  const accountNotificationsInactive = accountNotifications?.filter(
    (notification: NotificationModel) => notification.inactive,
  );

  const privateNotifications = relevantNotifications?.filter(
    (notification: NotificationModel) => notification.private,
  );

  const privateNotificationsActive = privateNotifications?.filter(
    (notification: NotificationModel) => !notification.inactive,
  );
  const privateNotificationsInactive = privateNotifications?.filter(
    (notification: NotificationModel) => notification.inactive,
  );

  const irrelevantNotifyOrAddedNotifications = notifications?.filter(
    (notification: NotificationModel) =>
      (notification.type !== NotificationType.RELEVANT &&
        notification.state === ItemState.NOTIFY) ||
      (notification.added && !isApprovedState(notification.state)),
  );

  const categoriesFiltered = notificationCategories?.filter(
    (t: INotificationCategoriesModel) =>
      t.notifications?.filter(
        (notification: NotificationModel) =>
          notification.type === NotificationType.NOT_RELEVANT &&
          notification.state !== ItemState.NOTIFY &&
          notification.category !== 'CARD_PAYMENTS',
      ).length > 0,
  );

  return (
    <Container>
      <div className='notifications-section'>
        <div className='section-header'>
          <div className='header-block'>
            <h2>Auswahl der Zahlungspartner</h2>
            {manualOrHasAutomaticNotifications ? (
              <Tooltip tooltipSectionName={TooltipStateEnum.NOTIFICATION_HEADER}>
                <p>
                  Fügen Sie hier Zahlungspartner hinzu, die Sie auf Ihr neues Konto umziehen
                  möchten. Wir erledigen den Rest und informieren diese über Ihre neue
                  Bankverbindung.
                </p>
              </Tooltip>
            ) : (
              <Tooltip tooltipSectionName={TooltipStateEnum.NOTIFICATION_HEADER}>
                <p>
                  Wir haben aus Ihrem Kontoauszug alle für den Umzug relevanten Zahlungspartner
                  ausgewählt und vorgemerkt.
                </p>
                <br />
                <p>Über die Checkboxen können Sie die Auswahl anpassen.</p>
                <br />
                <p>
                  Bitte vervollständigen Sie bei gelb markierten Zahlungspartnern die Kontaktdaten.
                </p>
              </Tooltip>
            )}
          </div>

          <p>Welche Zahlungspartner sollen über Ihre neue Bankverbindung informiert werden?</p>

          {kwsState!.transactionTimeframe !== undefined &&
          kwsState!.transactionTimeframe !== 0 &&
          kwsState!.transactionTimeframe < 100 &&
          kwsState!.workflow !== WorkflowEnum.MANUAL_ATTACH_IBAN ? (
            <div className='dac-alert'>
              <InfoBox
                type='alert'
                closeButton={{ sessionTitle: 'c24-hundred-days-alert-ignore' }}
                title='Eingeschränkte Datenverfügbarkeit'
              >
                <p>
                  Leider haben wir von Ihrer bisherigen Bank Kontoauszüge von weniger als 100 Tagen
                  erhalten. Daher können halb- oder jährliche Zahlungen wie z.B. KfZ-Versicherung,
                  Grundsteuer, etc. nicht automatisch erkannt werden.
                </p>

                <p>Bitte ergänzen Sie fehlende Zahlungen manuell.</p>
              </InfoBox>
            </div>
          ) : null}
        </div>

        <div>
          {relevantNotifications?.length > 0 && (
            <div>
              <div className='notifications'>
                {regularNotifications?.length > 0 && (
                  <>
                    <div className='sub-header'>
                      <h2>Regelmäßige Zahlungen</h2>
                      {(relevantNotifications?.length > 0 ||
                        irrelevantNotifyOrAddedNotifications?.length > 0) && (
                        <Sort
                          onChange={sortNotifications}
                          selected={sessionStorage.getItem('c24-sort-1')}
                          relevancy='relevant'
                        />
                      )}
                    </div>
                    <InnerBorderContainer>
                      {regularNotificationsActive.map((t: NotificationModel) => (
                        <Notification key={t.id} notification={t} />
                      ))}
                      {regularNotificationsInactive.map((t: NotificationModel) => (
                        <Notification key={t.id} notification={t} />
                      ))}
                    </InnerBorderContainer>
                  </>
                )}

                {accountNotifications?.length > 0 && (
                  <>
                    <div className='sub-header'>
                      <h2>Kundenkonten mit Zahlungsdaten</h2>
                      <Tooltip tooltipSectionName={TooltipStateEnum.NOTIFICATION_ACCOUNT}>
                        <p>
                          Hier finden Sie Ihre Kundenkonten, bei denen Sie Zahlungsinformationen
                          hinterlegt haben (z.B. für Online-Shopping).
                        </p>
                      </Tooltip>
                    </div>
                    <InnerBorderContainer>
                      {accountNotificationsActive.map((t: NotificationModel) => (
                        <Notification key={t.id} notification={t} />
                      ))}
                      {accountNotificationsInactive.map((t: NotificationModel) => (
                        <Notification key={t.id} notification={t} />
                      ))}
                    </InnerBorderContainer>
                  </>
                )}

                {privateNotifications?.length > 0 && (
                  <>
                    <div className='sub-header'>
                      <h2>Private Transaktionen</h2>
                      <Tooltip tooltipSectionName={TooltipStateEnum.NOTIFICATION_PRIVATE}>
                        <p>
                          Hier finden Sie Ihre private Transaktionen, bei denen Sie Daten ggf.
                          selbst eingeben oder ergänzen müssen
                        </p>
                      </Tooltip>
                    </div>
                    <InnerBorderContainer>
                      {privateNotificationsActive.map((t: NotificationModel) => (
                        <Notification key={t.id} notification={t} />
                      ))}
                      {privateNotificationsInactive.map((t: NotificationModel) => (
                        <Notification key={t.id} notification={t} />
                      ))}
                    </InnerBorderContainer>
                  </>
                )}
              </div>
            </div>
          )}
          {irrelevantNotifyOrAddedNotifications?.length > 0 && (
            <div className='recent-section'>
              <div className='sub-header'>
                <h2>Manuell hinzugefügt</h2>
              </div>
              <div>
                <div className='notifications'>
                  <InnerBorderContainer>
                    {irrelevantNotifyOrAddedNotifications.map((t: NotificationModel) => (
                      <Notification key={t.id} notification={t} />
                    ))}
                  </InnerBorderContainer>
                </div>
              </div>
            </div>
          )}
          {manualAndNoNotification && (
            <div className='info-box-wrapper'>
              <InfoBox title='Keine Zahlungspartner ausgewählt' type='alert'>
                <p data-testid='manual-noNotification-content'>
                  Bitte fügen Sie hier Zahlungspartner hinzu, die Sie über Ihre neue Bankverbindung
                  informieren möchten.
                </p>
              </InfoBox>
            </div>
          )}
          <div className='add-notification'>
            <AddButton title='Zahlungspartner hinzufügen' onClick={createNewNotification} />
          </div>
          {manualOrHasAutomaticNotifications ? null : (
            <div onClick={() => toggleExpand()} className='toggle-2'>
              <div>Vermissen Sie einen Zahlungspartner?</div>
              <CarretIcon className={`carret ${expand ? 'rotate-0' : 'rotate-180'}`} />
            </div>
          )}

          {expand && categoriesFiltered && categoriesFiltered.length > 0 && (
            <div className='partners'>
              <div className='sub-header'>
                <h2>Sonstige Zahlungspartner</h2>
                <Tooltip tooltipSectionName={TooltipStateEnum.NOTIFICATION_PARTNERS}>
                  <p>
                    Sie können den Kontoumzug für Ihre sonstigen Zahlungspartner hier individuell
                    anpassen. Kartenzahlungen mit Ihrer Giro- oder Debitkarte müssen nicht umgezogen
                    werden und sind nicht aufgeführt.
                  </p>
                </Tooltip>
              </div>

              <p className='text'>
                Folgende Gut- und Lastschriften wurden für Ihren Kontoumzug als nicht relevant
                identifiziert. Bei Bedarf können Sie diese über die Checkbox zu Ihrem Kontoumzug
                hinzufügen.
              </p>

              {categoriesFiltered?.map((t: INotificationCategoriesModel) => (
                <NotificationCategory key={t.category} notifications={t.notifications} />
              ))}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default NotificationsSection;
