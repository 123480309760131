import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import Intro from '../../CommonSections/Intro';
import { isDesktopOrTablet, scrollToPosition } from '../../../../utils/helper';
import { NotificationDetailsModel } from '../../../../types/Overview/NotificationModel';
import SpinnerFullPage from '../../../Common/SpinnerFullPage';
import { ValidationErrorNotification } from '../../../../utils/validators';
import ServiceGateway from '../../../../services/ServiceGateway';
import TabButtons from '../../../Common/TabButtons';
import NotificationDetailTab from './components/NotificationDetailTab';
import AllTransactionsTab from '../../CommonSections/AllTransactionsTab';
import { ItemType } from '../../../../types/Overview/Common';
import ResetButton from '../../../Common/ResetButton';
import Container from '../../../Common/Container';

const NotificationDetails = () => {
  const { kwsState, notificationToProcess, setNotificationToProcess, setSwitchingDateToProcess } =
    useKwsState();
  const { pageType, id: notificationId } = notificationToProcess!;
  const [validationError, setValidationError] = useState<ValidationErrorNotification>({});
  const [notificationDetailState, setNotificationDetailState] =
    useState<NotificationDetailsModel>();

  const { data: notificationResponse, isLoading: isNotificationLoading } = useQuery({
    queryKey: ['notification-details', kwsState!.id, notificationId!],
    queryFn: async () => {
      const response = await ServiceGateway.getNotification(kwsState!.id, notificationId!);
      return response.data;
    },
    enabled: pageType === 'EDIT',
    select: (data) =>
      // we set the name of the recipient to the normalizedCounterName if the name is missing
      !data.recipient?.name
        ? { ...data, recipient: { ...data.recipient, name: data.normalizedCounterName } }
        : data,
  });

  const { data: preCreateNotificationResponse, isLoading: isPreCreateNotificationLoading } =
    useQuery({
      queryKey: ['pre-create-notification', kwsState!.id],
      queryFn: async () => {
        const response = await ServiceGateway.preCreateNotification(kwsState!.id);
        return response.data;
      },
      enabled: pageType === 'ADD',
    });

  const validateNotication = () => {
    const notificationResponseTmp = notificationResponse!;

    let nameErrorCheck = false;
    let streetErrorCheck = false;
    let streetNumErrorCheck = false;
    let cityErrorCheck = false;
    let postalCodeErrorCheck = false;

    if (!notificationResponseTmp.recipient?.name) {
      nameErrorCheck = true;
    }
    if (notificationResponseTmp?.recipient?.address?.germanAddress !== false) {
      if (notificationResponseTmp.private && !notificationResponseTmp.recipient?.address?.street) {
        streetErrorCheck = true;
      }
      if (
        notificationResponseTmp.private &&
        !notificationResponseTmp.recipient?.address?.streetNum
      ) {
        streetNumErrorCheck = true;
      }
      if (!notificationResponseTmp.recipient?.address?.city) {
        cityErrorCheck = true;
      }
      if (!notificationResponseTmp.recipient?.address?.postalCode) {
        postalCodeErrorCheck = true;
      }
    }

    const unvalidObject = {
      valid: false,
      message: 'Bitte geben Sie die fehlenden Informationen ein',
    };
    const validObject = {
      valid: true,
    };

    setValidationError((validations) => ({
      ...validations,
      FIELD: {
        ...validations?.FIELD,
        'recipient.name': nameErrorCheck ? unvalidObject : validObject,
        'recipient.address.street': streetErrorCheck ? unvalidObject : validObject,
        'recipient.address.streetNum': streetNumErrorCheck ? unvalidObject : validObject,
        'recipient.address.city': cityErrorCheck ? unvalidObject : validObject,
        'recipient.address.postalCode': postalCodeErrorCheck ? unvalidObject : validObject,
      },
    }));
  };

  useEffect(() => {
    if (notificationResponse && pageType === 'EDIT') {
      validateNotication();
      setNotificationDetailState(notificationResponse);
    }
  }, [notificationResponse]);

  useEffect(() => {
    if (preCreateNotificationResponse && pageType === 'ADD') {
      setNotificationDetailState(preCreateNotificationResponse);
    }
  }, [preCreateNotificationResponse]);

  useEffect(() => {
    scrollToPosition('0');
  }, []);

  const goBack = () => {
    setNotificationToProcess(undefined);
    setSwitchingDateToProcess(false);
  };

  if (!notificationDetailState || isNotificationLoading || isPreCreateNotificationLoading) {
    return <SpinnerFullPage />;
  }

  const tabData = [
    {
      id: 0,
      title: 'Mitteilung',
      content: (
        <NotificationDetailTab
          initialValidationError={validationError}
          notificationDetailState={notificationDetailState}
          setNotificationDetailState={setNotificationDetailState}
        />
      ),
    },
    ...(notificationDetailState.transactions && notificationDetailState.transactions.length !== 0
      ? [
          {
            id: 1,
            title: 'Transaktionen',
            content: (
              <AllTransactionsTab
                transactions={notificationDetailState!.transactions!}
                pageType={ItemType.NOTIFICATION}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <div className='notification-details' data-testid='notification-details'>
      <ResetButton title='zurück zur Übersicht' onClick={goBack} isSticky={!isDesktopOrTablet()} />

      {pageType === 'EDIT' && (
        <Intro
          data={notificationDetailState}
          pageType='notification'
          classNames={tabData.length > 1 ? 'bottom-border' : ''}
        />
      )}

      {pageType === 'ADD' && (
        <Container classNames='mobile-shadow equal-padding detail-pages-bottom-margin'>
          <h2>Zahlungspartner hinzufügen</h2>
        </Container>
      )}

      {tabData.length > 1 ? (
        <TabButtons
          tabs={tabData}
          classNames={isDesktopOrTablet() ? 'sticky' : 'sticky-with-button-mobile'}
        />
      ) : (
        tabData[0].content
      )}
    </div>
  );
};

export default NotificationDetails;
