import React, { useEffect, useState } from 'react';
import ServiceGateway, { logoutSystem } from '../../../services/ServiceGateway';
import ContactCard from '../../Common/ContactCard';
import WelcomeSection from './sections/WelcomeBanner/index';
import SwitchingDateSection from './sections/SwitchingDate/index';
import NotificationsSection from '../CommonSections/Notifications';
import StandingOrdersSection from '../CommonSections/StandingOrders';
import AccountClosingSection from '../CommonSections/AccountClosing/index';
import GuaranteeInfoSection from '../CommonSections/GuaranteeInfo/index';
import { useKwsState } from '../../../contexts/KwsStateContext/KwsStateContext';
import { useOverview } from '../../../contexts/OverviewContext/OverviewContext';
import {
  checkHasNotifyState,
  filterRelevantNotifications,
  getLastScrollPosition,
  removeModifiedTransaction,
  scrollToPosition,
} from '../../../utils/helper';
import { WorkflowEnum } from '../../../contexts/KwsStateContext/KwsState.types';
import './index.scss';
import SpinnerFullPage from '../../Common/SpinnerFullPage';
import ResetSection from './sections/ResetSection';
import { ItemState } from '../../../types/Overview/Common';
import ActionButtons from '../../Common/ActionButtons';
import { NotificationModel } from '../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../types/Overview/StandingOrderModel';
import { FrontEndSection } from '../../../contexts/FrontendRuleContext/FrontendRule.types';
import { useFrontendState } from '../../../contexts/FrontendRuleContext/FrontendRuleContext';
import ConfirmationModal from '../CommonSections/ConfirmationModal';

// #region alerts
const incompleteNotifModalNode: React.ReactNode = (
  <div className='confirmation-modal-content'>
    <p>Fehlende Daten ergänzen</p>
    <p>Es wurden Zahlungspartner mit unvollständigen Daten gefunden.</p>
    <p>
      <b>Hinweis: </b>
      Zahlungspartner mit unvollständigen Daten können nicht über Ihre neue Bankverbindung
      informiert werden.
    </p>
    <p>
      Falls Sie diese Zahlungspartner nicht informieren oder zu einem späteren Zeitpunkt die Daten
      ergänzen möchten, fahren Sie fort zur Unterschrift.
    </p>
  </div>
);

const noNotificationModalNode: React.ReactNode = (
  <div className='confirmation-modal-content'>
    <p>Keine Zahlungspartner ausgewählt</p>
    <p>
      <b>Achtung: </b>
      Es werden keine Zahlungspartner auf Ihr neues Konto umgezogen.
    </p>
    <p>
      Falls Sie nur die Schließung Ihres bisherigen Kontos wünschen, fahren Sie weiter fort zur
      Unterschrift.
    </p>
  </div>
);

const onlySOModalNode: React.ReactNode = (
  <div className='confirmation-modal-content'>
    <p>Keine Zahlungspartner ausgewählt</p>
    <p>
      <b>Achtung: </b>
      Es werden keine Zahlungspartner über Ihre neue Bankverbindung informiert.
    </p>
    <p>
      Falls Sie nur die Einrichtung Ihrer Daueraufträge wünschen, fahren Sie weiter fort zur
      Unterschrift.
    </p>
  </div>
);

const standingOrderAndACModalNode: React.ReactNode = (
  <div className='confirmation-modal-content'>
    <p>Keine Zahlungspartner ausgewählt</p>
    <p>
      <b>Achtung: </b>
      Es werden keine Zahlungspartner über Ihre neue Bankverbindung informiert.
    </p>
    <p>
      Falls Sie nur die Einrichtung Ihrer Daueraufträge und die Schließung Ihres bisherigen Kontos
      wünschen, fahren Sie weiter fort zur Unterschrift.
    </p>
  </div>
);
// #endregion

const Overview = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { kwsState, refreshKwsState } = useKwsState();
  const { overviewData, fillOverviewData } = useOverview();
  const { decideSectionToShow } = useFrontendState();
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);

  const handleSubmit = () => {
    setIsLoading(true);
    ServiceGateway.completeEditing(kwsState!.id).then(() => {
      refreshKwsState(undefined, () => setIsLoading(false));
    });
  };

  const checkEmptyNotifications = (callback: () => void) => {
    const isNotificationsEmpty = overviewData!.notifications?.length === 0;
    const hasNotifyState = checkHasNotifyState(overviewData!.notifications);

    const noNotifications =
      (kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN && isNotificationsEmpty) ||
      !hasNotifyState;

    if (noNotifications) {
      if (checkHasNotifyState(overviewData!.standingOrders)) {
        const isACNotify = overviewData?.closingData.state === ItemState.NOTIFY;
        setModalContent(isACNotify ? standingOrderAndACModalNode : onlySOModalNode);
        return;
      }

      setModalContent(noNotificationModalNode);
    } else {
      callback();
    }
  };

  const checkIncompleteNotifications = (callback: () => void) => {
    const relevantNotifications = filterRelevantNotifications(overviewData!.notifications);
    const hasAnyIncompleteNotif = relevantNotifications.some(
      (no: NotificationModel) =>
        no.state === ItemState.INCOMPLETE || no.state === ItemState.TO_COMPLETE,
    );

    const hasAnyIncompleteSO = overviewData?.standingOrders.some(
      (so: StandingOrderModel) =>
        so.state === ItemState.INCOMPLETE || so.state === ItemState.TO_COMPLETE,
    );

    if (hasAnyIncompleteNotif || hasAnyIncompleteSO) {
      setModalContent(incompleteNotifModalNode);
    } else {
      callback();
    }
  };

  const submit = () => {
    // !Order of functions is important!
    checkEmptyNotifications(() => checkIncompleteNotifications(() => handleSubmit()));
  };

  useEffect(() => {
    const scrollPosition = getLastScrollPosition();
    scrollToPosition(scrollPosition);
  }, [overviewData]);

  const getOverviewData = async () => {
    const response = await ServiceGateway.getOverviewPage(kwsState!.id);
    fillOverviewData(response.data);
  };

  useEffect(() => {
    getOverviewData();

    return () => removeModifiedTransaction();
  }, []);

  if (!overviewData) {
    return <SpinnerFullPage />;
  }

  const hasNotifyState = checkHasNotifyState(overviewData.notifications);
  const hasNotifySO = checkHasNotifyState(overviewData.standingOrders);
  const isNotificationsEmpty = overviewData.notifications?.length === 0;

  const isCTAButtonEnabled =
    hasNotifyState || hasNotifySO || overviewData.closingData.state === ItemState.NOTIFY;

  return (
    <div className='overview' data-testid='overview-page'>
      {kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN && <ResetSection />}

      {decideSectionToShow(FrontEndSection.WELCOME) && <WelcomeSection />}

      {decideSectionToShow(FrontEndSection.SWITCHING_DATE) && !isNotificationsEmpty && (
        <SwitchingDateSection />
      )}

      {decideSectionToShow(FrontEndSection.NOTIFICATIONS) && <NotificationsSection />}

      {decideSectionToShow(FrontEndSection.STANDING_ORDERS) &&
        !(kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN) && <StandingOrdersSection />}

      {decideSectionToShow(FrontEndSection.CLOSE_ACCOUNT) && <AccountClosingSection />}

      {decideSectionToShow(FrontEndSection.CTA_BUTTON) && (
        <ActionButtons
          isDisabled={!isCTAButtonEnabled}
          isLoading={isLoading}
          proceedButton={{ label: 'weiter zur Unterschrift', onClick: submit }}
          cancelButton={{ label: 'speichern und abmelden', onCancel: logoutSystem }}
        />
      )}

      {decideSectionToShow(FrontEndSection.GUARANTEE_INFO) && <GuaranteeInfoSection />}

      {decideSectionToShow(FrontEndSection.CONTACT_CARD) && <ContactCard />}

      {modalContent && (
        <ConfirmationModal
          onCancel={() => setModalContent(null)}
          onSubmit={handleSubmit}
          submitLabel='weiter zur Unterschrift'
          hasCancelButton
          cancelButtonLabel='zurück'
        >
          {modalContent}
        </ConfirmationModal>
      )}
    </div>
  );
};

export default Overview;
