import React, { useState } from 'react';
import './index.scss';
import ServiceGateway from '../../../../services/ServiceGateway';

import Container from '../../../Common/Container';
import { isApprovedState } from '../../../../utils/helper';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import Tooltip from '../../../Common/Tooltip';
import { useOverview } from '../../../../contexts/OverviewContext/OverviewContext';
import { ItemState } from '../../../../types/Overview/Common';
import { ReactComponent as CheckIcon } from '../../../../assets/check.svg';
import { TooltipStateEnum } from '../../../../contexts/TooltipStateContext/Tooltip.types';
import RadioButtonGroup, {
  RadioButtonGroupLayoutDirection,
  RadioProps,
} from '../../../Common/RadioButtonGroup';
import AccountClosingItem from './components/AccountClosingItem';

enum CloseAccount {
  CLOSE = 'CLOSE',
  KEEP = 'KEEP',
}

const AccountClosingSection = () => {
  const { kwsState } = useKwsState();
  const { overviewData, updateClosingData } = useOverview();
  const { closingData } = overviewData!;
  const [isRadioChangeDisabled, setIsRadioChangeDisabled] = useState(false);

  if (isApprovedState(closingData.state)) {
    return null;
  }

  const onRadioChange = (buttonValue: CloseAccount) => {
    const isClosing = buttonValue === CloseAccount.CLOSE;

    setIsRadioChangeDisabled(true);
    ServiceGateway.editClosingData(kwsState!.id, isClosing).then((res) => {
      setIsRadioChangeDisabled(false);
      updateClosingData(res.data);
    });
  };

  const isClosingDateToBeNotified = closingData.state === ItemState.NOTIFY;

  const radioButtons: RadioProps[] = [
    {
      onChange: onRadioChange,
      value: CloseAccount.CLOSE,
      label: 'Ja',
      name: 'close-account',
      checked: isClosingDateToBeNotified,
      disabled: isRadioChangeDisabled,
    },

    {
      onChange: onRadioChange,
      value: CloseAccount.KEEP,
      label: 'Nein',
      name: 'close-account',
      checked: !isClosingDateToBeNotified,
      disabled: isRadioChangeDisabled,
    },
  ];

  return (
    <Container>
      <div className='account-closing-section' data-testid='account-closing-section'>
        <div className='section-header'>
          <div className='header-block'>
            <h2>Schließung des bisherigen Kontos</h2>
            <Tooltip tooltipSectionName={TooltipStateEnum.ACCOUNT_CLOSING_HEADER}>
              <p>Hier können Sie die Kündigung Ihres bisherigen Kontos beauftragen.</p>
              <br />
              <p>
                Das frühestmögliche Datum zur Schließung Ihres bisherigen Kontos wird automatisch
                errechnet. Sie können auch ein späteres Datum auswählen.
              </p>
            </Tooltip>
          </div>
          <p>Möchten Sie nach dem Kontoumzug Ihr bisheriges Konto kündigen?</p>
        </div>

        <RadioButtonGroup
          buttons={radioButtons}
          layoutDirection={RadioButtonGroupLayoutDirection.HORIZONTAL}
        />

        {isClosingDateToBeNotified && (
          <div className='details-section'>
            <AccountClosingItem />

            <div className='warnings check'>
              <CheckIcon className='modified-icon' />
              <p>
                Der Restsaldo wird bei Kontoschließung automatisch mit Ihrem neuen Konto verrechnet.
              </p>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default AccountClosingSection;
