import { OverviewBankModel } from '../Overview/ClosingDataModel';
import { GlobalDate } from '../Overview/GlobalDate';

export interface SignaturePageModel {
  globalDate: Partial<GlobalDate>;
  newBank: OverviewBankModel;
  oldBank: OverviewBankModel;
}

export enum UserStatusEnum {
  DEFAULT = 'default',
  READY = 'ready',
  UPDATED = 'updated',
}
