import React, { useState } from 'react';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';
import './index.scss';
import { isDesktopOrTablet } from '../../../utils/helper';
import Modal from '../Modal';
import HoverText from '../HoverText';

export enum StateInfoChipEnum {
  DEFAULT = 'default',
  ALERT = 'alert',
  SUCCESS = 'success',
}

interface StateInfoChipsProps {
  label: string;
  type?: StateInfoChipEnum;
  children?: React.ReactNode;
}

const StateInfoChips = ({ label, type, children }: StateInfoChipsProps): React.JSX.Element => {
  const isDesktopOrTabletConst = isDesktopOrTablet();
  const [showInfo, setShowInfo] = useState(false);

  const renderPopup = (): React.JSX.Element => {
    if (isDesktopOrTabletConst) {
      return <HoverText>{children}</HoverText>;
    }

    return (
      <Modal onClose={() => setShowInfo(false)}>
        <div className='state-modal-content'>{children}</div>
      </Modal>
    );
  };

  return (
    <div className='state-info-chip' data-testid='state-info-chip'>
      <div
        className={`state-label ${type}`}
        onClick={() => setShowInfo(true)}
        onMouseEnter={() => isDesktopOrTabletConst && setShowInfo(true)}
        onMouseLeave={() => isDesktopOrTabletConst && setShowInfo(false)}
        data-testid='state-label'
      >
        {label}
        <InfoIcon />
      </div>

      {showInfo && children && renderPopup()}
    </div>
  );
};

export default StateInfoChips;
