import React from 'react';
import './index.scss';

interface HoverText {
  children: React.ReactNode;
}

const HoverText = ({ children }: HoverText) => (
  <div className='hover-text'>
    <div className='top-arrow' />
    <div className='content'>{children}</div>
  </div>
);

export default HoverText;
