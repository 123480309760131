import React from 'react';
import Entry from './Entry';
import './index.scss';

import ssFull from '../../../../assets/landing/ss-full.png';
import ss1 from '../../../../assets/landing/ss-1.png';
import ss2 from '../../../../assets/landing/ss-2.png';
import ss3 from '../../../../assets/landing/ss-3.png';
import ss4 from '../../../../assets/landing/ss-4.png';
import { OutCome } from '../..';
import { giroLinkGenerator, kundenLinkGenerator } from '../../utils/linkGenerator';
import { EnvironmentEnum } from '../../../../utils/environment';

interface EntrySectionProps {
  outCome: OutCome;
}

const EntrySection = ({ outCome }: EntrySectionProps) => {
  const environment = process.env.REACT_APP_ENV as EnvironmentEnum;

  const defaultEntries = [
    {
      title: 'Konto bei CHECK24 abschließen',
      description:
        'Nach Eröffnung des neuen Kontos erhalten Sie automatisch Zugang zum Kontoumzug.',
      list: [
        <>
          Beantragung des neuen Kontos über den{' '}
          {outCome === 'default'
            ? giroLinkGenerator('CHECK24 Girokontovergleich', environment)
            : 'CHECK24 Girokontovergleich'}
        </>,
        'Automatische Einladung zum Kontoumzug nach Legitimation und Kontoeröffnung',
        <>
          Kontoumzug jederzeit über{' '}
          {outCome === 'default' ? kundenLinkGenerator() : 'CHECK24 Kundenbereich'} starten
        </>,
      ],
    },
    {
      title: 'Mit altem Konto verbinden',
      description:
        'Starten Sie den Kontoumzug und verbinden Sie sich dort sicher mit dem Konto, das Sie umziehen möchten.',
      list: [
        'Anmeldung mit Online-Banking Zugangsdaten der alten Bank und TAN-Bestätigung',
        'Verschlüsselte Übertragung vertraulicher Daten ohne Zugriff oder Speicherung durch CHECK24',
        'TÜV-zertifiziert und BaFin geprüft',
      ],
      image: ss4,
    },
    {
      title: 'Automatische Kontoanalyse',
      description:
        'Wir schlagen relevante Zahlungspartner und Daueraufträge für den Kontoumzug vor.',
      list: [
        'Identifizierung regelmäßiger Gutschriften und Zahlungen',
        'Umzug von z. B. Gehaltseingang, Mietzahlung oder Mobilfunkrechnung empfohlen',
        'TÜV-bestätigter Datenschutz',
      ],
      image: ss1,
    },
    {
      title: 'Kontoumzug bestätigen',
      description:
        'Wählen Sie aus, was auf Ihr neues Konto umgezogen werden soll und schließen Sie Ihr altes Konto optional.',
      list: [
        'Volle Kontrolle bei Auswahl von Zahlungspartnern, Daueraufträgen und Zeitpunkt des Kontoumzugs',
        'Ein-Klick-Kontoumzug oder Detailbearbeitung der einzelnen Umzugsschritte',
        'Automatische Verrechnung des Restsaldos bei Kündigung des alten Kontos',
      ],
      image: ss2,
    },
    {
      title: 'Fertig',
      description: 'Nach Unterschrift wird der Kontoumzug automatisch ausgeführt.',
      list: [
        'Übersicht aller Umzugsschritte und Fristen',
        'Nachträglicher Umzug weiterer Zahlungspartner und Daueraufträge möglich',
        'Eingabe der neuen IBAN, falls noch nicht automatisch übertragen',
      ],
      image: ss3,
    },
  ];
  const entries = {
    default: defaultEntries,
    productinfo: defaultEntries,
    teaser: defaultEntries,
    cta: [
      {
        title: 'Mit altem Konto verbinden',
        description:
          'Starten Sie den Kontoumzug und verbinden Sie sich dort sicher mit dem Konto, das Sie umziehen möchten.',
        list: [
          'Anmeldung mit Online-Banking Zugangsdaten der alten Bank und TAN-Bestätigung',
          'Verschlüsselte Übertragung vertraulicher Daten ohne Zugriff oder Speicherung durch CHECK24',
          'TÜV-zertifiziert und BaFin geprüft',
        ],
        image: ss4,
      },
      {
        title: 'Automatische Kontoanalyse',
        description:
          'Wir schlagen relevante Zahlungspartner und Daueraufträge für den Kontoumzug vor.',
        list: [
          'Identifizierung regelmäßiger Gutschriften und Zahlungen',
          'Umzug von z. B. Gehaltseingang, Mietzahlung oder Mobilfunkrechnung empfohlen',
          'TÜV-bestätigter Datenschutz',
        ],
        image: ss1,
      },
      {
        title: 'Kontoumzug bestätigen',
        description:
          'Wählen Sie aus, was auf Ihr neues Konto umgezogen werden soll und schließen Sie Ihr altes Konto optional.',
        list: [
          'Volle Kontrolle bei Auswahl von Zahlungspartnern, Daueraufträgen und Zeitpunkt des Kontoumzugs',
          'Ein-Klick-Kontoumzug oder Detailbearbeitung der einzelnen Umzugsschritte',
          'Automatische Verrechnung des Restsaldos bei Kündigung des alten Kontos',
        ],
        image: ss2,
      },
      {
        title: 'Fertig',
        description: 'Nach Unterschrift wird der Kontoumzug automatisch ausgeführt.',
        list: [
          'Übersicht aller Umzugsschritte und Fristen',
          'Nachträglicher Umzug weiterer Zahlungspartner und Daueraufträge möglich',
          'Eingabe der neuen IBAN, falls noch nicht automatisch übertragen',
        ],
        image: ss3,
      },
    ],
    info: [
      {
        title: 'Legitimation durchführen',
        description:
          'Nach erfolgreicher Legitimation wird Ihr Konto eröffnet und Sie erhalten automatisch Zugang zum Kontoumzug.',
        list: [
          'Automatische Einladung zum Kontoumzug nach Legitimation und Kontoeröffnung',
          'Sofortiger Zugang bei VideoIdent über CHECK24',
          'Kontoumzug jederzeit über CHECK24 Kundenbereich starten',
        ],
      },
      {
        title: 'Mit altem Konto verbinden',
        description:
          'Starten Sie den Kontoumzug und verbinden Sie sich dort sicher mit dem Konto, das Sie umziehen möchten.',
        list: [
          'Anmeldung mit Online-Banking Zugangsdaten der alten Bank und TAN-Bestätigung',
          'Verschlüsselte Übertragung vertraulicher Daten ohne Zugriff oder Speicherung durch CHECK24',
          'TÜV-zertifiziert und BaFin geprüft',
        ],
        image: ss4,
      },
      {
        title: 'Automatische Kontoanalyse',
        description:
          'Wir schlagen relevante Zahlungspartner und Daueraufträge für den Kontoumzug vor.',
        list: [
          'Identifizierung regelmäßiger Gutschriften und Zahlungen',
          'Umzug von z. B. Gehaltseingang, Mietzahlung oder Mobilfunkrechnung empfohlen',
          'TÜV-bestätigter Datenschutz',
        ],
        image: ss1,
      },
      {
        title: 'Kontoumzug bestätigen',
        description:
          'Wählen Sie aus, was auf Ihr neues Konto umgezogen werden soll und schließen Sie Ihr altes Konto optional.',
        list: [
          'Volle Kontrolle bei Auswahl von Zahlungspartnern, Daueraufträgen und Zeitpunkt des Kontoumzugs',
          'Ein-Klick-Kontoumzug oder Detailbearbeitung der einzelnen Umzugsschritte',
          'Automatische Verrechnung des Restsaldos bei Kündigung des alten Kontos',
        ],
        image: ss2,
      },
      {
        title: 'Fertig',
        description: 'Nach Unterschrift wird der Kontoumzug automatisch ausgeführt.',
        list: [
          'Übersicht aller Umzugsschritte und Fristen',
          'Nachträglicher Umzug weiterer Zahlungspartner und Daueraufträge möglich',
          'Eingabe der neuen IBAN, falls noch nicht automatisch übertragen',
        ],
        image: ss3,
      },
    ],
  };

  return (
    <div className='entry-section'>
      <div className='wrapper'>
        <div className='content'>
          <h2>So funktioniert&apos;s</h2>
          <div className='entries'>
            {entries[outCome].map((e, i) => (
              <Entry
                key={i}
                number={i + 1}
                title={e.title}
                description={e.description}
                list={e.list}
                image={e.image}
              />
            ))}
          </div>
        </div>
        <div className='phone-image'>
          <img src={ssFull} alt='' />
        </div>
      </div>
    </div>
  );
};

export default EntrySection;
