import React from 'react';
import { DeviceOutputEnum } from '../../../utils/deviceOutput';
import { EnvironmentEnum } from '../../../utils/environment';

export const giroUrlGenerator = (env: EnvironmentEnum) => {
  const redirectUrl = `https://finanzen.check24${
    env === EnvironmentEnum.INTEGRATION || env === EnvironmentEnum.STAGING
      ? `-${env === 'staging' ? 'test' : env}`
      : ''
  }.de/ul/girokonto-vergleich?cpref=KWS_LP&appaction=result&userJourney=true&deviceoutput=${
    window.wf_deviceoutput
  }`;
  let target = '_self';
  switch (window.wf_deviceoutput) {
    case DeviceOutputEnum.DESKTOP:
      target = '_blank';
      break;
    case DeviceOutputEnum.TABLETAPP:
      target = '_blank';
      break;
    default:
      break;
  }

  return { redirectUrl, target };
};

export const giroLinkGenerator = (label: string, env = EnvironmentEnum.PROD) => {
  const giroUrls = giroUrlGenerator(env);

  return (
    <a href={giroUrls.redirectUrl} target={giroUrls.target} rel='noreferrer'>
      {label}
    </a>
  );
};

export const kundenUrlGenerator = () => {
  let redirectUrl =
    'https://kundenbereich.check24.de/user/account/activities.html?productgroup=finance';
  let target = '_self';
  switch (window.wf_deviceoutput) {
    case DeviceOutputEnum.DESKTOP:
      target = '_blank';
      break;
    case DeviceOutputEnum.MOBILE:
      redirectUrl = 'https://m.check24.de/kundenbereich/actions/finance/all';
      break;
    case DeviceOutputEnum.APP:
      redirectUrl = 'https://m.check24.de/kundenbereich/actions/all?product=finance';
      break;
    default:
      break;
  }

  return { redirectUrl, target };
};

export const kundenLinkGenerator = () => {
  const kundenUrls = kundenUrlGenerator();

  return (
    <a href={kundenUrls.redirectUrl} target={kundenUrls.target} rel='noreferrer'>
      CHECK24 Kundenbereich
    </a>
  );
};

export const guaranteeUrlGenerator = () => {
  let redirectUrl = 'https://check24.de/girokonto/funktioniert-garantiert-bedingungen/';
  let target = '_self';
  switch (window.wf_deviceoutput) {
    case DeviceOutputEnum.DESKTOP:
      target = '_blank';
      break;
    case DeviceOutputEnum.MOBILE:
      redirectUrl = 'https://m.check24.de/girokonto/funktioniert-garantiert-bedingungen/';
      break;
    case DeviceOutputEnum.APP:
      redirectUrl = 'https://m.check24.de/girokonto/funktioniert-garantiert-bedingungen/';
      break;
    default:
      break;
  }

  return { redirectUrl, target };
};

export const guaranteeLinkGenerator = () => {
  const guaranteeUrls = guaranteeUrlGenerator();

  return (
    <a href={guaranteeUrls.redirectUrl} target={guaranteeUrls.target} rel='noreferrer'>
      Details
    </a>
  );
};
